import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
// import { ClimbingBoxLoader } from 'react-spinners';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/styles';
import { GridLoader } from 'react-spinners';

import MuiTheme from './../theme';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
// Layout Blueprints

import { MinimalLayout } from './../layout-blueprints';

import { LeftSidebar } from './../layout-blueprints';

import PageError404 from './../pages/404';
import PageError403 from '../pages/403';

const TeamListing = lazy(() => import('../pages/Teams/TeamListing'));
const CreateTeam = lazy(() => import('../pages/Teams/CreateTeam'));
const EditTeam = lazy(() => import('../pages/Teams/EditTeam'));
const Targets = lazy(() => import('../pages/Targets/'));
const ListCampaigns = lazy(() => import('../pages/Campaigns/ListCampaigns'));
const AddCampaign = lazy(() => import('../pages/Campaigns/AddCampaign'));
const EditCampaign = lazy(() => import('../pages/Campaigns/EditCampaign'));
const UtmPromotions = lazy(() => import('../pages/UtmPromotions/Listing'));
const CreatePromotion = lazy(() => import('../pages/UtmPromotions/Create'));
const EditPromotion = lazy(() => import('../pages/UtmPromotions/Edit'));

const DashboardCommerce = lazy(() => import('./../pages/DashboardCommerce'));
const Login = lazy(() => import('./../pages/login'));
const ForgotPassword = lazy(() => import('./../pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./../pages/ResetPassword'));
const Register = lazy(() => import('./../pages/register'));

// users routes
const UserListing = lazy(() => import('./../pages/Users/UserListing'));
const EditUser = lazy(() => import('./../pages/Users/EditUser'));
const CreateUser = lazy(() => import('./../pages/Users/CreateUser'));
const MyAccount = lazy(() => import('./../pages/Users/MyAccount'));

// roles routes
const RoleListing = lazy(() => import('./../pages/Roles/RoleListing'));
const CreateRole = lazy(() => import('./../pages/Roles/CreateRole'));
const EditRole = lazy(() => import('./../pages/Roles/EditRole'));

const ListTemplates = lazy(() =>
  import('./../pages/EmailTemplates/ListTemplates')
);
const EditTemplate = lazy(() =>
  import('./../pages/EmailTemplates/EditTemplate')
);

// Client request routes
const ClientRequestList = lazy(() =>
  import('./../pages/ClientRequest/ClientRequestList')
);

// customers routes
const ListLeads = lazy(() => import('./../pages/Leads/ListLeads'));
const EditLead = lazy(() => import('./../pages/Leads/EditLead'));
const Clients = lazy(() => import('./../pages/clients/Clients'));
const ClientDetail = lazy(() => import('./../pages/clients/ClientDetail'));

// Transaction routes
const Deposit = lazy(() => import('./../pages/Transaction/Deposit'));
const Credit = lazy(() => import('./../pages/Transaction/Cridit'));
const Withdraw = lazy(() => import('./../pages/Transaction/Withdraw'));
const InternalTransfer = lazy(() =>
import('./../pages/Transaction/InternalTransfer')
);
const WireTransfer = lazy(() => import('./../pages/Transaction/WireTransfer'));
// Reminders

const Reminders = lazy(() => import('./../pages/Reminders'));

// Reporting

const Reporting = lazy(() => import('./../pages/Reporting'));

// Request routes
const IbRequest = lazy(() => import('../pages/Request/IbRequest'));
const NewAccountRequest = lazy(() => import('../pages/Request/NewAccount'));
const ChangeLeverage = lazy(() =>
  import('../pages/Request/ChangeLeverage/ChangeLeverage')
);
const PromotionRequests = lazy(() =>
  import('../pages/Request/PromotionRequests/PromotionRequests')
);

//Campaign Custom Email Tempaltes
const ListCampaignTemplates = lazy(() =>
  import('../pages/CampaignTemplates/ListTemplates')
);
const EditCampaignTemplate = lazy(() =>
  import('./../pages/CampaignTemplates/EditTemplate')
);

const AddCampaignTemplate = lazy(() =>
  import('./../pages/CampaignTemplates/CreateTemplate/CreateTemplate')
);

//Email Campaign
const ListEmailCampaigns = lazy(() =>
  import('../pages/EmailCampaigns/ListEmailCampaigns')
);
const AddEmailCampaign = lazy(() => import('../pages/EmailCampaigns/AddEmailCampaign'));
const EditEmailCampaign = lazy(() => import('../pages/EmailCampaigns/EditEmailCampaign'));
const CampaignUnsubscribe = lazy(() =>
  import('./../pages/EmailCampaigns/helpers/CampaignUnsubscribe')
);
const UserLogs = lazy(() => import('../pages/Users/UserLogs'));

const ListCampaignsUnsubscribers = lazy(() =>import('../pages/EmailCampaigns/ListUnsubscribers'));

const Routes = () => {
  const location = useLocation();
  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3
  };

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);
    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}>
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                  <GridLoader color={'#395b77'} loading={true} />
                </div>
                <div className="text-muted font-size-xl text-center pt-3">
                  Please wait while we load interface
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
    /* return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}>
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                  <ClimbingBoxLoader color={'#3c44b1'} loading={true} />
                </div>
                <div className="text-muted font-size-xl text-center pt-3">
                  Please wait while we load the live preview examples
                  <span className="font-size-lg d-block text-dark">
                    This live preview instance can be slower than a real
                    production build!
                  </span>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    ); */
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <SnackbarProvider maxSnack={1}>
        <AnimatePresence>
          <Suspense fallback={<SuspenseLoading />}>
            <Switch>
              <Redirect exact from="/" to="/login" />

              <Route
                path={[
                  '/Dashboard',
                  '/users',
                  '/marketing/link',
                  '/teams',
                  '/campaigns',
                  '/targets',
                  '/demo-accounts',
                  '/roles',
                  '/user-logs',
                  '/clients',
                  '/email-templates',
                  '/client-requests',
                  '/leads',
                  '/transactions',
                  '/requests',
                  '/reminders',
                  '/reporting',
                  '/my-account',
                  '/transaction/deposit',
                  '/transaction/credit',
                  '/transaction/withdraw',
                  '/transaction/internal-transfer',
                  '/transaction/wire-transfer',
                  '/requests/ib',
                  '/requests/new-account',
                  '/requests/promotion-requests',
                  '/campaign-templates',
                  '/campaign',
                  '/campaign-unsubscribers'
                ]}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <PrivateRoute
                        path="/Dashboard"
                        component={DashboardCommerce}
                      />
                      {/* <Route path="/users" component={UserListing} />
                    <PrivateRoute path="/create-user" component={CreateUser} />
                    <PrivateRoute path="/edit-user" component={EditUser} /> */}

                      <PrivateRoute
                        path="/client-requests"
                        component={ClientRequestList}
                      />
                      <Route
                        path={[
                          '/users',
                          '/marketing/link',
                          '/teams',
                          '/campaigns',
                          '/targets',
                          '/demo-accounts',
                          '/leads',
                          '/clients',
                          '/roles',
                          '/user-logs',
                          '/email-templates',
                          '/transactions',
                          '/requests',
                          '/reminders',
                          '/reporting',
                          '/my-account',
                          '/transaction/deposit',
                          '/transaction/credit',
                          '/transaction/withdraw',
                          '/transaction/internal-transfer',
                          '/transaction/wire-transfer',
                          '/requests/ib',
                          '/requests/new-account',
                          '/requests/promotion-requests',
                          '/campaign-templates',
                          '/campaign',
                          '/campaign-unsubscribers'
                        ]}>
                        <MinimalLayout>
                          <Switch location={location} key={location.pathname}>
                            <motion.div
                              initial="initial"
                              animate="in"
                              exit="out"
                              variants={pageVariants}
                              transition={pageTransition}>
                              <Route
                                path="/teams"
                                exact
                                component={TeamListing}
                              />
                              <Route
                                path="/teams/create"
                                component={CreateTeam}
                              />
                              <Route path="/teams/edit" component={EditTeam} />
                              <Route
                                path="/targets"
                                exact
                                component={Targets}
                              />
                              <Route
                                path="/campaigns"
                                exact
                                component={ListCampaigns}
                              />
                              <Route
                                path="/campaigns/add"
                                exact
                                component={AddCampaign}
                              />
                              <Route
                                path="/campaigns/edit"
                                component={EditCampaign}
                              />
                              <Route
                                path="/users"
                                exact
                                component={UserListing}
                              />
                              <Route
                                path="/users/create"
                                component={CreateUser}
                              />
                              <Route path="/my-account" component={MyAccount} />
                              <Route path="/users/edit" component={EditUser} />

                              <Route
                                path="/marketing/link"
                                exact
                                component={UtmPromotions}
                              />
                              <Route
                                path="/marketing/link/create"
                                component={CreatePromotion}
                              />
                              <Route
                                path="/marketing/link/edit"
                                component={EditPromotion}
                              />

                              <Route
                                path="/user-logs"
                                exact
                                component={UserLogs}
                              />
                              <Route
                                path="/roles"
                                exact
                                component={RoleListing}
                              />
                              <Route
                                path="/roles/create"
                                component={CreateRole}
                              />
                              <Route path="/roles/edit" component={EditRole} />
                              <Route
                                path="/leads"
                                exact
                                component={ListLeads}
                              />
                              <Route path="/leads/edit" component={EditLead} />
                              <Route
                                path="/email-templates"
                                exact
                                component={ListTemplates}
                              />
                              <Route
                                path="/email-templates/:emailTemplateId"
                                component={EditTemplate}
                              />
                              <Route
                                path="/leads/:clientId"
                                component={ClientDetail}
                              />
                              <Route
                                path="/clients"
                                exact
                                component={Clients}
                              />
                              <Route
                                exact
                                path="/clients/:clientId"
                                component={ClientDetail}
                              />
                              <Route
                                path="/requests"
                                exact
                                component={Request}
                              />
                              <Route path="/reminders" component={Reminders} />
                              {/* <Route
                                path="/transactions"
                                exact
                                component={Transaction}
                              /> */}
                              <Route
                                path="/reporting"
                                exact
                                component={Reporting}
                              />
                              <Route
                                path="/requests/ib"
                                exact
                                component={IbRequest}
                              />
                              <Route
                                path="/requests/new-account"
                                exact
                                component={NewAccountRequest}
                              />
                              <Route
                                path="/requests/change-leverage"
                                exact
                                component={ChangeLeverage}
                              />
                              <Route
                                path="/requests/promotion-requests"
                                exact
                                component={PromotionRequests}
                              />
                              <Route
                                path="/transaction/deposit"
                                exact
                                component={Deposit}
                              />
                              <Route
                                path="/transaction/credit"
                                exact
                                component={Credit}
                              />
                              <Route
                                path="/transaction/withdraw"
                                exact
                                component={Withdraw}
                              />
                              <Route
                                path="/transaction/internal-transfer"
                                exact
                                component={InternalTransfer}
                              />
                              <Route
                                path="/transaction/wire-transfer"
                                exact
                                component={WireTransfer}
                              />

                                <Route
                                path="/campaign-templates"
                                exact
                                component={ListCampaignTemplates}
                              />
                                <Route
                                path="/campaign-templates/edit/:campaignEmailTemplateId"
                                component={EditCampaignTemplate}
                              />

                                <Route
                                path="/campaign-templates/add"
                                component={AddCampaignTemplate}
                              />
                              
                                <Route
                                path="/campaign"
                                exact
                                component={ListEmailCampaigns}
                              />
                                <Route
                                path="/campaign/add"
                                exact
                                component={AddEmailCampaign}
                              />

                              <Route
                                path="/campaign/edit/:campaignId"
                                exact
                                component={EditEmailCampaign}
                              />

                              <Route
                                path="/campaign-unsubscribers"
                                exact
                                component={ListCampaignsUnsubscribers}
                              />
                            </motion.div>
                          </Switch>
                        </MinimalLayout>
                      </Route>
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>

              <Route
                path={[
                  '/Login',
                  '/Forgot-Password',
                  '/Reset-Password',
                  '/Register',
                  '/PageError404',
                  '/PageError403',
                  '/unsubscribe-email/:emailId'
                ]}>
                <MinimalLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <PublicRoute
                        restricted={true}
                        path="/Login"
                        component={Login}
                      />
                      <PublicRoute
                        restricted={true}
                        path="/Forgot-Password"
                        component={ForgotPassword}
                      />
                      <PublicRoute
                        restricted={true}
                        path="/Reset-Password"
                        component={ResetPassword}
                      />
                      <PublicRoute
                        restricted={true}
                        path="/Register"
                        component={Register}
                      />
                       <PublicRoute
                        path="/unsubscribe-email/:emailId"
                        component={CampaignUnsubscribe}
                      />
                      <Route path="/PageError403" component={PageError403} />
                      <Route path="/PageError404" component={PageError404} />
                    </motion.div>
                  </Switch>
                </MinimalLayout>
              </Route>
              <Redirect from="/" to="/PageError404" />
            </Switch>
          </Suspense>
        </AnimatePresence>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default Routes;
