import { GET_CLIENT_JOURNEYS, GET_CLIENT_IB_JOURNEYS, RESET_PAGE_CLIENT_LOGS } from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  page: 1,
  totalPages: 1,
  limit: 5,
  journeys: [],
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENT_JOURNEYS:
      return { ...state, ...action.data };
    case GET_CLIENT_IB_JOURNEYS:
      return { ...state, ...action.data };
    case RESET_PAGE_CLIENT_LOGS:
      return { ...initialState };
    default:
      return state;
  }
};

export default clientReducer;
