import { removeUser } from '../utils/auth';
import { history } from './..';

// export const apiHost =
//   process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : '';

export const apiHost =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : '';
export const paymentHost =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3007' : '';
// export const apiHost =
//   process.env.NODE_ENV === 'development'
//     ? 'https://engine-rest-service.azurewebsites.net/'
//     : 'https://engine-rest-service.azurewebsites.net/';


const storedUserKey = 'CurrentUser';
class ApiService {
  constructor(payments = false) {
    if (payments) {
      this.apiUrl = `${paymentHost}/gatetopay`;
    } else {
      this.apiUrl = `${apiHost}/api/v1`;
    }
  }

  get authInfo() {
    try {
      const storedUser = localStorage.getItem(storedUserKey);
      const data = storedUserKey ? JSON.parse(storedUser) : null;
      return data;
    } catch (error) {
      return error;
    }
  }

  post = async (url, body, needAuth, token) => {
    try {
      const resp = await this.makeRequest({
        url,
        method: 'POST',
        body,
        needAuth,
        token
      });
      return this.handleResponse(resp);
    } catch (error) {
      throw error;
    }
  };

  put = async (url, body, needAuth, token) => {
    try {
      const resp = await this.makeRequest({
        url,
        method: 'PUT',
        body,
        needAuth,
        token
      });
      return this.handleResponse(resp);
    } catch (error) {
      throw error;
    }
  };

  formData = async (url, body, needAuth, token) => {
    try {
      const resp = await this.makeRequest({
        url,
        method: 'POST',
        body,
        needAuth,
        token,
        isFormData: true
      });
      return this.handleResponse(resp);
    } catch (error) {
      throw error;
    }
  };

  patch = async (url, body, needAuth, token) => {
    try {
      const resp = await this.makeRequest({
        url,
        method: 'PATCH',
        body,
        needAuth,
        token
      });
      return this.handleResponse(resp);
    } catch (error) {
      this.handleError(error);
      return Promise.reject(error);
    }
  };

  get = async (url, needAuth = true, options = {}) => {
    try {
      const resp = await this.makeRequest({
        url,
        needAuth,
        ...options
      });
      return this.handleResponse(resp);
    } catch (error) {
      this.handleError(error);
      return Promise.reject(error);
    }
  };

  getNoParse = async (url, needAuth = true) => {
    try {
      const fullUrl = `${this.apiUrl}/${url}`;
      const method = 'GET';
      let headers = {};
      if (needAuth && this.authInfo) {
        const { username, token } = this.authInfo;
        headers = {
          ...headers,
          Authorization: token || username
        };
      }

      const options = {
        method,
        headers
      };
      const response = await fetch(fullUrl, options);

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  delete = async (url, body, needAuth = true) => {
    try {
      const resp = await this.makeRequest({
        url,
        method: 'DELETE',
        needAuth,
        body
      });
      return this.handleResponse(resp);
    } catch (error) {
      this.handleError(error);
      return Promise.reject(error);
    }
  };

  handleResponse = ({
    result,
    code,
    isSuccess,
    isError,
    errors,
    message
  } = {}) => {
    if (result) {
      return {
        result,
        message,
        isSuccess,
        isError
      };
    }
    if (isError && code === 401) {
      this.logoutUser();
    }
    return Promise.reject({
      ...{ errors },
      isSuccess,
      isError,
      ...{ message },
      code
    });
  };

  logoutUser() {
    removeUser();
    history.push('/login');
    window.location.reload();
  }

  redirectTo(link = '/dashboard') {
    history.push(link);
  }

  handleError = (error) => { };

  makeRequest = async ({
    url = '',
    method = 'GET',
    body = null,
    needAuth = true,
    token = null,
    isFormData = false,
    signal,
  }) => {
    try {
      const fullUrl = `${this.apiUrl}/${url}`;

      let headers = isFormData
        ? {}
        : {
          'Content-Type': 'application/json'
        };

      if (needAuth && this.authInfo) {
        const { username, token } = this.authInfo;
        headers = {
          ...headers,
          Authorization: token || username
        };
      }

      const options = {
        method,
        headers
      };
      if (method !== 'GET' && method !== 'OPTIONS' && body && !isFormData) {
        try {
          options.body = JSON.stringify(body || {});
        } catch (error) {
          options.body = body;
        }
      }
      if (isFormData) {
        options.body = body;
      }
      if (signal) {
        options.signal = signal;
      }
      const response = await fetch(fullUrl, options);

      return response.json();
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export default ApiService;

export const API = new ApiService();
export const PaymentAPI = new ApiService(true);

