export const CP_BASE_URL =
  process.env.REACT_APP_CP_BASE_URL || 'http://localhost:3010/';
export const CP_REGISTER_LIVE = `${CP_BASE_URL}RegisterLive`;
export const CP_REGISTER_DEMO = `${CP_BASE_URL}RegisterDemo`;

export const REGISTER_URL = process.env.REACT_APP_REGISTER_URL;

export const MARKUPS = {
  Executive: ['real\C00R00+10', 'real\C00R00+20'],
  VIP: ['real\C00R00+10', 'real\C00R00+20'],
};
export const restSocketUrl = process.env.REACT_APP_REST_SOCKET_URL || 'http://localhost:3001';

export const isDemo = false
export const showViewPassword = false;