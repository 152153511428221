import { combineReducers } from 'redux';
import ThemeOptions from './ThemeOptions';
import auth from './authReducer';
import users from './userReducer';
import teams from './teamReducer';
import roles from './roleReducer';
import apiCallsInProgress from './apiStatusReducer';
import dashboardReducer from './dashboardReducer';
import emailTemplatesReducer from './emailTemplatesReducer';
import documents from './documentReducer';
import bankDetailsReducer from './bankDetailsReducer';
import clientDetailReducer from './clientDetailReducer';
import clinetActivities from './clientActivityReducer';
import clientAccounts from './clientAccountsReducer';
import clinetTransactions from './clientTransactionReducer';
import { reducer as notifications } from 'react-notification-system-redux';
import depositTransactions from './depositTransactionReducer';
import withdrawTransactions from './withdrawTransactionReducer';
import internalTransferTransactions from './internalTransferTransactionReducer';
import wireTransferTransactions from './wireTransferTransactionReducer';
import ibRequests from './ibRequestReducer';
import changeLeverageRequests from './changeLeverageReducer';
import newAccountRequests from './newAccountRequestReducer';
import clients from './clientReducer';
import clientsJourney from './clientJourneyReducer';
import clientsIbJourney from './clientIbJourneyReducer';
import clientRequests from './clientRequestsReducer';
import clientNotes from './clientNotesReducer';
import leadsReducer from './leadsReducer';
import targetReducer from './targetReducer';
import reportingReducer from './reportingReducer';
import campaigns from './campaignsReducer';
import notification from './notificationReducer';
import ib from './ibReducer';
import clientStatementReducer from './clientStatementReducer';
import creditTransactions from './creditsReducer';
import promotionRequests from './promotionRequestsReducer';
import utmPromotion from './utmPromotionReducer';
//import currencies from './currrencyReducer';
import whatsapp from './whtasappMsgReducer'
import configs from './configReducer'
// new reducers
import clientDocuments from './clientDocuments.reducer';
import quickActions from './quickActionsReducer';

//textSms
import textsmsActions from './textSmsReducer'

import campaignEmailTemplates from './campaignEmailTemplatesReducer'
import multiCampaigns from './multiCampaignsReducer'
export default combineReducers({
  ThemeOptions,
  auth,
  users,
  teams,
  roles,
  apiCallsInProgress,
  dashboardReducer,
  emailTemplatesReducer,
  documents,
  bankDetailsReducer,
  clientDetailReducer,
  clinetActivities,
  clientAccounts,
  clinetTransactions,
  campaigns,
  notifications,
  depositTransactions,
  internalTransferTransactions,
  wireTransferTransactions,
  withdrawTransactions,
  clients,
  clientsJourney,
  clientsIbJourney,
  clientRequests,
  clientNotes,
  configs,
  leadsReducer,
  ibRequests,
  newAccountRequests,
  changeLeverageRequests,
  notification,
  targetReducer,
  reportingReducer,
  clientDocuments,
  ib,
  clientStatementReducer,
  creditTransactions,
  promotionRequests,
  utmPromotion,
  quickActions,
  //currencies,
  whatsapp,
  textsmsActions,
  campaignEmailTemplates,
  multiCampaigns
});
