import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import { apiHost } from '../api';

import { countryCodesMap } from 'common/country-codes';

export const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

export const getNestedObject = (nestedObj, pathArr) => {
  return pathArr.reduce(
    (obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined),
    nestedObj
  );
};

export const updateItemInItems = (items, index, dataToBeUpdate) => {
  const newItems = [...items];
  const newItem = Object.assign({}, newItems[index], dataToBeUpdate);
  newItems[index] = newItem;
  console.log('NN', newItems);
  return newItems;
};

export const permissionChecker = (group, permission, permissions) => {
  if (!permissions || permissions.length === 0) return false;
  const filteredPermissions = permissions.filter(
    (perm) => perm.key === group
  )[0];
  // if (!filteredPermissions.status) return false;
  if (filteredPermissions) {
    const check = filteredPermissions.permissions.filter(
      (perm) => perm.key === permission
    )[0];
    return check ? check.status : false;
  }
  return false;
};

export const getTransactionPermissionCheckerString = (pathname) => {
  return pathname.includes('deposit')
    ? 'deposit'
    : pathname.includes('withdraw')
    ? 'withdraw'
    : pathname.includes('internal')
    ? 'internal'
    : '';
};

export const getRequestPermissionCheckerString = (pathname) => {
  return pathname.includes('ib')
    ? 'ib'
    : pathname.includes('account')
    ? 'account'
    : '';
};

export const getPermissionValues = (permissions, group, arrayPermission) => {
  let perms = {};
  for (let i = 0; i < arrayPermission.length; i++) {
    window[`${arrayPermission[i]}Permissions`] = '';
    window[`${arrayPermission[i]}Permissions`] = permissionChecker(
      group,
      arrayPermission[i],
      permissions
    );
    perms = {
      ...perms,
      [`${arrayPermission[i]}Permissions`]: window[
        `${arrayPermission[i]}Permissions`
      ]
    };
  }
  return perms;
};

export const getClientLeadPermissionCheckerString = (pathname) =>
  `${pathname.includes('client') ? 'client' : 'lead'}`;

export const getPermissionGroup = (prefix, group, pathname) => {
  let moddedGroup = '';
  let name = '';
  if (group === 'transactions')
    name = getTransactionPermissionCheckerString(pathname);
  else if (group === 'request')
    name = getRequestPermissionCheckerString(pathname);
  else if (group.includes('client__lead'))
    name = getClientLeadPermissionCheckerString(pathname);
  else name = pathname;
  if (prefix) {
    moddedGroup = `${group}${name === null || name === '' ? '' : '__'}${
      name ? name : ''
    }`;
  } else {
    moddedGroup = `${name}__${
      group.includes('client__lead') ? group.split('___')[1] : group
    }`;
  }
  return moddedGroup;
};

const removeUndefinedFromObj = (obj) => pickBy(obj, identity);

export const getQueryString = (obj) =>
  new URLSearchParams(removeUndefinedFromObj(obj)).toString();

export const statusStyle = (status) => {
  switch (status) {
    case 'Approved':
    case 'APPROVED':
    case 'Activated':
    case 'Created':
      return { color: '#00c188' };
    case 'Rejected':
    case 'REJECTED':
    case 'Deactivated':
    case 'Deleted':
      return { color: '#f83245' };
    default:
      return { color: '#cdcd1d' };
  }
};

export const getSortObjectFromString = (sortString) => {
  const tmp = sortString.split(',');
  return {
    [tmp[0]]: parseInt(tmp[1])
  };
};

export const getCustomerType = (value = 0) => {
  let rightsNum = value;
  const output = {
    isClient: false,
    isIb: false,
    isJoint: false,
    isCorporate: false,
    isCorporateIb: false
  };
  if (rightsNum === 0) return output;
  if (rightsNum > 0 && Math.floor(rightsNum / 16) >= 1)
    output.isCorporateIb = true;
  rightsNum %= 16;
  if (rightsNum > 0 && Math.floor(rightsNum / 8) >= 1)
    output.isCorporate = true;
  rightsNum %= 8;
  if (rightsNum > 0 && Math.floor(rightsNum / 4) >= 1) output.isJoint = true;
  rightsNum %= 4;
  if (rightsNum > 0 && Math.floor(rightsNum / 2) >= 1) output.isIb = true;
  rightsNum %= 2;
  if (rightsNum > 0 && Math.floor(rightsNum / 1) >= 1) output.isClient = true;
  return output;
};

export const replaceArrayItemsWithAnotherArrayById = (
  oldArray = [],
  newArray = []
) =>
  oldArray.map(
    (item) => {
      const search = newArray.find((item2) => item._id.toString() === item2._id.toString());
      if (search) {
        return {...item, ...search};
      } else {
        return item;
      }
    }
      // newArray.find((item2) => item._id.toString() === item2._id.toString()) ||
      // item
  );

const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
const isEmail = (email) => email && email.match(emailRegex);

let lastEntered = {
  email: '',
  valid: false
};
export const emailBackendValidity = (value) => {
  console.log(value, lastEntered);

  if (value !== lastEntered.email && isEmail(value)) {
    lastEntered.email = value;
    lastEntered.valid = false;
    return new Promise((resolve, reject) => {
      fetch(`${apiHost}/api/v1/customers/email-availability`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: value
        })
      })
        .then(async (response) => {
          const res = await response.json();
          if (!res.result.availability) resolve(false);
          else {
            resolve(true);
            lastEntered.valid = true;
          }
        })
        .catch((error) => {
          resolve(true);
        });
    });
  } else if (value === lastEntered.email && lastEntered.valid)
    return new Promise((resolve, reject) => resolve(true));
  else if (value === lastEntered.email && !lastEntered.valid)
    return new Promise((resolve, reject) => resolve(false));
};

export const getBase64File = (file) => {
  const reader = new FileReader();
  return new Promise((resolve) => {
    reader.onloadend = (e) => {
      resolve(e.target.result);
    };
    reader.readAsDataURL(file);
  });
};

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const formatNumber = (number, fixDecimal = false, decimalSize = 2) => {
  if (isNaN(number)) return fixDecimal ? '--.--' : '--';
  let num = number;
  if (fixDecimal) {
    num = parseFloat(num);
    num = num.toFixed(decimalSize);
  }
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const emptyCountryData = () => {
  const mapData = [];
  countryCodesMap.map((country) => {
    let item = { code: country.code, count: 0 };
    mapData.findIndex((c) => c.code === country.code) === -1 &&
      mapData.push(item);
  });
  return mapData;
};

export const populateMapData = (mapData = [], data = []) => {
  data.map((cdata) => {
    let country = countryCodesMap.find((c) => c.name === cdata.country);
    if (country) {
      mapData.map((md) => {
        if (md.code === country.code) {
          md.count += parseInt(cdata.count);
        }
        return md;
      });
    }
    return cdata;
  });
  return mapData;
};
