import { GET_CLIENT_JOURNEYS, GET_CLIENT_IB_JOURNEYS } from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  page: 1,
  totalPages: 1,
  limit: 5,
  ibJourneys: []
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENT_IB_JOURNEYS:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export default clientReducer;
