import sidebarLogo from 'assets/images/logo/gold-era-white.png';
import loginLogo from 'assets/images/logo/gold-era-full.png';
import loginBg from 'assets/images/hero-bg/wave-6.png';
export const app_config = {
  logo: '',
  clientName: 'Gold Era',
  loginLogo: loginLogo,
  loginBg: loginBg,
  sidebarLogo: sidebarLogo,
  //customLogo: customLogo,
  sidebarTitle: 'Gold Era CRM',
  regDemoUrl: 'https://my.gold-era.com/registerdemo',
  regLiveUrl: 'https://my.gold-era.com/registerlive',
  regIbUrl: 'https://my.gold-era.com/registerib'
};
