import { API } from '../../api';
import { isDemo } from 'settings';
import demoJSON from 'utils/demoDummyData'
import {
  DATA_CLIENTS_ERROR,
  DATA_CLIENTS_SUCCESS,
  DATA_IBS_ERROR,
  DATA_IBS_SUCCESS,
  DATA_KYC_PENDING_ERROR,
  DATA_KYC_PENDING_SUCCESS,
  DATA_LEADS_ERROR,
  DATA_LEADS_SUCCESS,
  DATA_REQUESTS_PENDING_ERROR,
  DATA_REQUESTS_PENDING_SUCCESS,
  DATA_TRANSACTIONS_PENDING_ERROR,
  DATA_TRANSACTIONS_PENDING_SUCCESS,
  GET_CONFIG,
  GET_CONFIG_FAILURE,
  GET_CONFIG_SUCCESS,
  GET_MY_PROFILE_FAILURE,
  GET_MY_PROFILE_SUCCESS,
  GET_RECENT_NOTES,
  RESET_PAGE_DASHBOARD,
  GET_MAP_DATA,
  GET_SALES_STAT_DATA,
  GET_IB_STAT_DATA,
  GET_REBCOMM_STAT_DATA
} from '../actions/actionTypes';
import { beginApiCall, apiCallError, endApiCall } from './apiActions';

import { emptyCountryData, populateMapData } from 'utils';


export function resetPageDashboard(profile) {
  return { type: RESET_PAGE_DASHBOARD, profile };
}

export function loadMyProfileSuccess(profile) {
  return { type: GET_MY_PROFILE_SUCCESS, profile };
}

export function loadMyProfileFailure(message, isError) {
  return { type: GET_MY_PROFILE_FAILURE, errorMessage: message, isError };
}

export function getAllLeadsSuccess(data) {
  return { type: DATA_LEADS_SUCCESS, data };
}

export function getAllLeadsFailure(message, isError) {
  return { type: DATA_LEADS_ERROR, errorMessage: message, isError };
}

export function getAllClientsSuccess(data) {
  return { type: DATA_CLIENTS_SUCCESS, data };
}

export function getAllClientsFailure(message, isError) {
  return { type: DATA_CLIENTS_ERROR, errorMessage: message, isError };
}

export function getAllIbsSuccess(data) {
  return { type: DATA_IBS_SUCCESS, data };
}

export function getAllIbsFailure(message, isError) {
  return { type: DATA_IBS_ERROR, errorMessage: message, isError };
}

export function getPendingRequestsSuccess(data) {
  return { type: DATA_REQUESTS_PENDING_SUCCESS, data };
}

export function getPendingRequestsFailure(message, isError) {
  return { type: DATA_REQUESTS_PENDING_ERROR, errorMessage: message, isError };
}

export function getPendingTransactionsSuccess(data) {
  return { type: DATA_TRANSACTIONS_PENDING_SUCCESS, data };
}

export function getPendingTransactionsFailure(message, isError) {
  return {
    type: DATA_TRANSACTIONS_PENDING_ERROR,
    errorMessage: message,
    isError
  };
}

export function getPendingKycSuccess(data) {
  return { type: DATA_KYC_PENDING_SUCCESS, data };
}

export function getPendingKycFailure(message, isError) {
  return { type: DATA_KYC_PENDING_ERROR, errorMessage: message, isError };
}

export function getDashboardReminders(payload) {
  return { type: GET_RECENT_NOTES, payload };
}

export function getDashboardMapData(payload) {
  return { type: GET_MAP_DATA, payload };
}

export function getDashboardSalesData(payload) {
  return { type: GET_SALES_STAT_DATA, payload };
}

export function getDashboardIBData(payload) {
  return { type: GET_IB_STAT_DATA, payload };
}

export function getDashboardRebCommData(payload) {
  return { type: GET_REBCOMM_STAT_DATA, payload };
}

export function getConfigAction() {
  return {
    type: GET_CONFIG
  };
}

export function getConfigSuccess(data) {
  return {
    type: GET_CONFIG_SUCCESS,
    data
  };
}

export function getConfigFailure(message, isError) {
  return {
    type: GET_CONFIG_FAILURE,
    errorMessage: message,
    isError
  };
}

export const getMyProfile = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch(beginApiCall());
      const res = await API.get('auth/profile', params, true);
      const { result, isSuccess, errors } = res;
      dispatch(loadMyProfileSuccess(result));
    } catch (error) {
      const { message, isError } = error;
      dispatch(loadMyProfileFailure(message, isError));
      dispatch(apiCallError());
      return await Promise.reject(error);
    }
  };
};

export const getAllLeads = (params = {}) => {
  const { role } = params;
  return async (dispatch) => {
    try {
      dispatch(beginApiCall());
      let newResult = null
      
      if(isDemo){
        newResult = demoJSON.leadsNewResult;
      }else{
        newResult = await API.get(
          `dashboard/data/leads?since=1&type=new`,
          params,
          true
        );
      }
      
      const { result: neww } = newResult;
      let data = {
        leads: {
          new: neww.new
        }
      };

      let allResult = null
      
      if(isDemo){
        allResult = demoJSON.leadsAll;
      }else{
        allResult = await API.get(
          `dashboard/data/leads?type=all`,
          params,
          true
        );
      }      
      const { result: all } = allResult;
      data.leads = {
        all: all.all,
        ...data.leads
      };

      let unassignedResult = null
      
      if(isDemo){
        unassignedResult = demoJSON.leadsUnassigned;
      }else{
        unassignedResult = await API.get(
          `dashboard/data/leads?type=unassigned`,
          params,
          true
        );
      }
       
        const { result: unassigned } = unassignedResult;
        data.leads = {
          ...data.leads,
          unassigned: unassigned.unassigned
        };
      // } else {
      //   data.leads = {
      //     ...data.leads,
      //     unassigned: 0
      //   };
      // }

      dispatch(getAllLeadsSuccess(data));
    } catch (e) {
      const { message, isError } = e;
      dispatch(getAllLeadsFailure(message, isError));
      dispatch(apiCallError());
      return await Promise.reject(e);
    }
  };
};

export const getAllClients = (params = {}) => {
  const { role } = params;
  return async (dispatch) => {
    try {
      dispatch(beginApiCall());
      let newResult = null
      
      if(isDemo){
        newResult = demoJSON.clientsNewResult;
      }else{
        newResult = await API.get(
          `dashboard/data/clients?since=1&type=new`,
          params,
          true
        );
      }
      const { result: neww } = newResult;
      let data = {
        clients: {
          new: neww.new
        }
      };

      let allResult = null
      
      if(isDemo){
        allResult = demoJSON.clientsAll;
      }else{
        allResult = await API.get(
          `dashboard/data/clients?type=all`,
          params,
          true
        );
      }
      const { result: all } = allResult;
      data.clients = {
        all: all.all,
        ...data.clients,
      };
        let unassignedResult = null
        if(isDemo){
          unassignedResult = demoJSON.clientsUnassigned;
        }else{
          unassignedResult = await API.get(
            `dashboard/data/clients?type=unassigned`,
            params,
            true
          );
        }
        const { result: unassigned } = unassignedResult;
        data.clients = {
          ...data.clients,
          unassigned: unassigned.unassigned
        };
      // } else {
      //   data.clients = {
      //     ...data.clients,
      //     unassigned: 0
      //   };
      // }
      
      dispatch(getAllClientsSuccess(data));
    } catch (e) {
      const { message, isError } = e;
      dispatch(getAllClientsFailure(message, isError));
      dispatch(apiCallError());
      return await Promise.reject(e);
    }
  };
};

export const getRecentReminders = () => {
  return async (dispatch) => {
    try {
      dispatch(beginApiCall());
      let reminders = null
      
      if(isDemo){
         reminders = demoJSON.remainders;
      }else{
         reminders = await API.get(`dashboard/reminders`, {}, true);
      }
      
      dispatch(getDashboardReminders(reminders.result));
    } catch (error) {
      dispatch(apiCallError());
      return await Promise.reject(error);
    }
  }
}

export const getAllIbs = (params = {}) => {
  const { role } = params;
  return async (dispatch) => {
    try {
      dispatch(beginApiCall());
      

      let allResult = null
      if(isDemo){
        allResult = demoJSON.ibNewResult;
      }else{
        allResult = await API.get(
          `dashboard/data/ibs?since=1&type=new`,
          params,
          true
        );
      }
      
      const { result: all } = allResult;
      let data = {
        ibs: {
          new: all.new
        }
      };
      
      if (role === 'admin' || role === 'operation') {
        
        let unassignedResult = null
        if(isDemo){
          unassignedResult = demoJSON.ibUnassigned;
        }else{
          unassignedResult = await API.get(
            `dashboard/data/ibs?type=unassigned`,
            params,
            true
          );
        }
        
        
        const { result: unassigned } = unassignedResult;
        
        let allResult = null
        if(isDemo){
          allResult = demoJSON.ibAll;
        }else{
          allResult = await API.get(
            `dashboard/data/ibs?type=all`,
            params,
            true
          );
        }
        
        
        const { result: all } = allResult;
        data.ibs = {
          all: all.all,
          ...data.ibs,
          unassigned: unassigned.unassigned
        };
      } else {
        data.ibs = {
          ...data.ibs,
          all: 0,
          unassign: 0
        }
      }
      dispatch(getAllIbsSuccess(data));
    } catch (e) {
      const { message, isError } = e;
      dispatch(getAllIbsFailure(message, isError));
      dispatch(apiCallError());
      return await Promise.reject(e);
    }
  };
};

export const getPendingRequests = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch(beginApiCall());
      let requestResponse = null
      
      if(isDemo){
        requestResponse = demoJSON.pendingReq;
      }else{
        requestResponse = await API.get(
          `dashboard/data/requests/all?since=30`,
          params,
          true
        );
      }
      
      const { result: all } = requestResponse;
      // const newAccountResponse = await API.get(
      //   `dashboard/data/requests?since=30&type=new-account&status=Pending`,
      //   params,
      //   true
      // );
      // const { result: newAccount } = newAccountResponse;
      // const partnershipResponse = await API.get(
      //   `dashboard/data/requests?since=30&type=partnership&status=Pending`,
      //   params,
      //   true
      // );
      // const { result: partnership } = partnershipResponse;
      // const changeLeverageResponse = await API.get(
      //   `dashboard/data/requests?since=30&type=change+leverage&status=Pending`,
      //   params,
      //   true
      // );
      // const { result: changeLeverage } = changeLeverageResponse;
      let data = {
        pendingRequests: {
          additional: all.additional,
          leverage: all.leverage,
          partnership: all.ibRequest,
          promotions: all.promotions,
          structure: all.structure
        }
      };
      
      dispatch(getPendingRequestsSuccess(data));
    } catch (e) {
      const { message, isError } = e;
      dispatch(getPendingRequestsFailure(message, isError));
      dispatch(apiCallError());
      return await Promise.reject(e);
    }
  };
};

export const getPendingTransactions = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch(beginApiCall());

      let depositResponse = null
      
      if(isDemo){
        depositResponse = demoJSON.depositResponse;
      }else{
        depositResponse = await API.get(
          `dashboard/data/transactions/all?since=30`,
          params,
          true
        );
      }
      const { result: all } = depositResponse;
      // const depositResponse = await API.get(
      //   `dashboard/data/transactions?since=30&type=DEPOSIT&status=PENDING`,
      //   params,
      //   true
      // );
      // const { result: deposit } = depositResponse;
      // const withdrawResponse = await API.get(
      //   `dashboard/data/transactions?since=30&type=WITHDRAWAL&status=PENDING`,
      //   params,
      //   true
      // );
      // const { result: withdraw } = withdrawResponse;
      // const internalResponse = await API.get(
      //   `dashboard/data/transactions?since=30&type=INTERNAL_TRANSFER&status=PENDING`,
      //   params,
      //   true
      // );
      // const { result: internal } = internalResponse;
      let data = {
        pendingTransactions: {
          deposit: all.deposit,
          withdrawal: all.withdrawal,
          credit: all.credit,
          internalTransfer: all.internalTransfer
        }
      };
      
      dispatch(getPendingTransactionsSuccess(data));
    } catch (e) {
      const { message, isError } = e;
      dispatch(getPendingTransactionsFailure(message, isError));
      dispatch(apiCallError());
      return await Promise.reject(e);
    }
  };
};

export const getPendingKyc = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch(beginApiCall());

      let res = null
      let result = null
      
      if(isDemo){
        result = demoJSON.pendingKyc;
      }else{
        res = await API.get(
          `dashboard/data/kyc?since=30&status=Pending&type=KYC_UPLOAD`,
          params,
          true
        );
        result = res.result
      }

     // const { result } = res;
      // approvedCount: 32
      // notUploadedCount: 135
      dispatch(
        getPendingKycSuccess({
          pendingVerifications: {
            // yetToUpload: result.Pending,
            // pendingApproval: result.pendingCount,
            // pendingWithoutDeposit: result.pendingWithoutDeposit,
            // rejected: result.rejectedCount,
            // yetToUpload: result.notUploadedCount,
            expiredDocuments: result.expiredDocuments,
            // approved: result.approvedCount,
            // dormant: 0
            // noDepositPendingApproval: noDeposit.Pending
            approvedKycUnfunded: result.approvedKycUnfunded,
            missingKyc: result.missingKyc,
            noKycFunded: result.noKycFunded,
            noKycUnfunded: result.noKycUnfunded,
            pendingKyc: result.pendingKyc,
            rejectedKyc: result.rejectedKyc,

          }
        })
      );
    } catch (e) {
      const { message, isError } = e;
      dispatch(getPendingKycFailure(message, isError));
      dispatch(apiCallError());
      return await Promise.reject(e);
    }
  };
};

export const getConfig = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch(beginApiCall());
      dispatch(getConfigAction());
      const { result: accountTypes } = await API.get(
        'configuration/account-types'
      );
      
      dispatch(
        getConfigSuccess({
          accountTypes
        })
      );
    } catch (e) {
      dispatch(apiCallError());
      return await Promise.reject(e);
    }
  };
};

export const getCustomersByCountry = (type = 'clients') => {
  return async (dispatch) => {
    try {
      dispatch(beginApiCall());
      
      
      let res = null
      let result = []
      if(isDemo){
        result = type === "clients" ? demoJSON.customersByCountryClients : demoJSON.customersByCountryLeads;
      }else{
        res = await API.get(
          `dashboard/stats/clientsbycountry?type=${type}`,
          true
        );
        result = res.result
      }


      let customerData = emptyCountryData();
      customerData = populateMapData(customerData, result);
      dispatch(getDashboardMapData({ data: customerData, type }));
      dispatch(endApiCall());
    } catch (error) {
      dispatch(apiCallError());
      return await Promise.reject(error);
    }
  };
};

export const getTransactionStats = (duration = 'week') => {
  return async (dispatch) => {
    try {
      dispatch(beginApiCall());
      
      let res = null
      let result = []
      
      if(isDemo){
        if(duration === "month"){
          result = demoJSON.transactionStatsMonthly
        }else if(duration === "year"){
          result = demoJSON.transactionStatsYearly
        } else {
          result = demoJSON.transactionStatsWeekly;
        }
      }else{
        res = await API.get(
          `dashboard/stats/transactions?duration=${duration}`,
          true
        );
        result = res.result
      }
      
      dispatch(
        getDashboardSalesData({
          meta:
            result[0].meta && result[0].meta.length > 0
              ? result[0].meta[0]
              : {},
          data: result[0].data
        })
      );
      
      dispatch(endApiCall());
    } catch (error) {
      dispatch(apiCallError());
      return await Promise.reject(error);
    }
  };
};

export const getIbStats = (duration = 'week') => {
  return async (dispatch) => {
    try {
      dispatch(beginApiCall());

      let res = null
      let result = []
      
      if (isDemo) {
        if (duration === 'month') {
          result = demoJSON.ibStatsMonthly;
        } else if (duration === 'year') {
          result = demoJSON.ibStatsYearly;
        } else {
          result = demoJSON.ibStatsWeekly;
        }
      } else {
        res = await API.get(
          `dashboard/stats/ibstats?duration=${duration}`,
          true
        );
        result = res.result;
      }

      dispatch(
        getDashboardIBData({
          meta:
            result[0].meta && result[0].meta.length > 0
              ? result[0].meta[0]
              : {},
          data: result[0].data
        })
      );
      dispatch(endApiCall());
    } catch (error) {
      dispatch(apiCallError());
      return await Promise.reject(error);
    }
  };
};

export const getRebCommStats = (duration = 'week') => {
  return async (dispatch) => {
    try {
      dispatch(beginApiCall());

      let res = null;
      let result = [];
      if (isDemo) {
        if (duration === 'month') {
          result = demoJSON.rebCommStatsMonthly;
        } else if (duration === 'year') {
          result = demoJSON.rebCommStatsYearly;
        } else {
          result = demoJSON.rebCommStatsWeekly;
        }
      } else {
        res = await API.get(
          `dashboard/stats/rebcommstats?duration=${duration}`,
          true
        );
        result = res.result;
      }

      dispatch(
        getDashboardRebCommData({
          meta:
            result[0].meta && result[0].meta.length > 0
              ? result[0].meta[0]
              : {},
          data: result[0].data
        })
      );
      dispatch(endApiCall());
    } catch (error) {
      dispatch(apiCallError());
      return await Promise.reject(error);
    }
  };
};
