const demoJSON = {
    remainders: {"result":{"notes":[{"callStatus":0,"quid":"60c1d53f4f5a1a030c1d70cd","status":"open","_id":"60c1d44f4f5a1a030c1d70cc","recordId":"CN37900244","note":"Deposit for 3100","remindAt":"2021-06-10T08:59:00.000Z","customerId":{"isClient":true,"_id":"60bcc13907feca71edb30bff","firstName":"Mohamed","lastName":"Khaled "},"author":{"firstName":"Mohammed h","lastName":"Fahad"},"createdAt":"2021-06-10T08:58:55.224Z","updatedAt":"2021-06-10T09:02:55.756Z"},{"callStatus":0,"quid":"60c1d1644f5a1a030c1d70c9","status":"open","_id":"60c1cfc44f5a1a030c1d70c5","recordId":"CN10773516","note":"Run Campaign ","remindAt":"2021-06-10T08:47:00.000Z","customerId":{"isClient":true,"_id":"60bcc13907feca71edb30bff","firstName":"Mohamed","lastName":"Aadil"},"author":{"firstName":"Mohammed h","lastName":"Fahad"},"createdAt":"2021-06-10T08:39:32.097Z","updatedAt":"2021-06-10T08:46:28.953Z"},{"callStatus":0,"quid":"60a115482c46572fa88eb801","status":"open","_id":"60a115442c46572fa88eb800","recordId":"CN70238182","note":"Add IB Structures","remindAt":"2021-05-27T08:02:00.000Z","customerId":{"isClient":true,"_id":"6093c8fc0691051c1c8304a7","firstName":"Mohammed","lastName":"Rashad"},"author":{"firstName":"Mohammed h","lastName":"Fahad"},"createdAt":"2021-05-16T12:51:16.270Z","updatedAt":"2021-05-16T12:51:21.277Z"}],"count":33},"isSuccess":true,"isError":false},
    pendingReq: {"result":{"leverage":{"pending":11,"rejected":10,"approved":69},"ibRequest":{"pending":18,"rejected":22,"approved":162},"additional":{"pending":82,"rejected":67,"approved":235},"promotions":{"pending":7,"rejected":5,"approved":8},"structure":{"pending":32,"rejected":13,"approved":51}},"isSuccess":true,"isError":false},
    depositResponse: {"result":{"deposit":{"pending":17,"rejected":4,"approved":390},"withdrawal":{"pending":31,"rejected":51,"approved":70},"internalTransfer":{"pending":5,"rejected":1,"approved":90},"credit":{"pending":0,"rejected":0,"approved":0}},"isSuccess":true,"isError":false},
    pendingKyc: {"_id":"1","pendingCount":2,"pendingWithoutDeposit":37,"approvalJourney":29,"rejectedCount":1,"notUploadedCount":23,"expiredDocuments":10, "approvedKycUnfunded": 67,"missingKyc": 90,"noKycFunded": 9,"noKycUnfunded": 8,"pendingKyc": 1,"rejectedKyc": 3},
    leadsNewResult: {"result":{"new":4},"isSuccess":true,"isError":false},
    leadsAll: {"result":{"all":608},"isSuccess":true,"isError":false},
    leadsUnassigned: {"result":{"unassigned":123},"isSuccess":true,"isError":false},
    clientsNewResult: {"result":{"new":3},"isSuccess":true,"isError":false},
    clientsAll: {"result":{"all":608},"isSuccess":true,"isError":false},
    clientsUnassigned: {"result":{"unassigned":10},"isSuccess":true,"isError":false},
    ibNewResult: {"result":{"new":2},"isSuccess":true,"isError":false},
    ibAll: {"result":{"all":608},"isSuccess":true,"isError":false},
    ibUnassigned: {"result":{"unassigned":123},"isSuccess":true,"isError":false},
    customersByCountryClients: [{"_id":"United States","country":"United States","count":2},{"_id":"Algeria","country":"Algeria","count":33},{"_id":"Egypt","country":"Egypt","count":2},{"_id":"Belize","country":"Belize","count":1},{"_id":"Armenia","country":"Armenia","count":6},{"_id":"UAE","country":"UAE","count":1},{"_id":"Cyprus","country":"Cyprus","count":1},{"_id":"Bahamas","country":"Bahamas","count":3},{"_id":"Azerbaijan","country":"Azerbaijan","count":4},{"_id":"Antigua and Barbuda","country":"Antigua and Barbuda","count":4},{"_id":"Antarctica","country":"Antarctica","count":1},{"_id":"Bhutan","country":"Bhutan","count":1},{"_id":"Angola","country":"Angola","count":80},{"_id":"Andorra","country":"Andorra","count":16},{"_id":"Pakistan","country":"Pakistan","count":4},{"_id":"Albania","country":"Albania","count":52},{"_id":"Bouvet Island","country":"Bouvet Island","count":1},{"_id":"Argentina","country":"Argentina","count":8},{"_id":"Gambia","country":"Gambia","count":1},{"_id":null,"country":null,"count":10},{"_id":"Bangladesh","country":"Bangladesh","count":1},{"_id":"Zimbabwe","country":"Zimbabwe","count":1},{"_id":"Aruba","country":"Aruba","count":7},{"_id":"Afghanistan","country":"Afghanistan","count":93},{"_id":"China","country":"China","count":1},{"_id":"Australia","country":"Australia","count":2},{"_id":"Austria","country":"Austria","count":4},{"_id":"Barbados","country":"Barbados","count":1},{"_id":"American Samoa","country":"American Samoa","count":33},{"_id":"Anguilla","country":"Anguilla","count":3},{"_id":"Belarus","country":"Belarus","count":1},{"_id":"Bahrain","country":"Bahrain","count":6},{"_id":"Jordan","country":"Jordan","count":7},{"_id":"United Arab Emirates","country":"United Arab Emirates","count":114}],
    customersByCountryLeads: [{"_id":"Hong Kong","country":"Hong Kong","count":1},{"_id":"Jordan","country":"Jordan","count":1},{"_id":"Algeria","country":"Algeria","count":9},{"_id":"Ukraine","country":"Ukraine","count":1},{"_id":null,"country":null,"count":421},{"_id":"Armenia","country":"Armenia","count":1},{"_id":"Cayman Islands","country":"Cayman Islands","count":1},{"_id":"Austria","country":"Austria","count":1},{"_id":"Afghanistan","country":"Afghanistan","count":5},{"_id":"Aruba","country":"Aruba","count":2},{"_id":"Tajikistan","country":"Tajikistan","count":1},{"_id":"Angola","country":"Angola","count":2},{"_id":"American Samoa","country":"American Samoa","count":10},{"_id":"Antigua and Barbuda","country":"Antigua and Barbuda","count":2},{"_id":"Azerbaijan","country":"Azerbaijan","count":1},{"_id":"United Arab Emirates","country":"United Arab Emirates","count":127},{"_id":"Argentina","country":"Argentina","count":5},{"_id":"India","country":"India","count":2},{"_id":"Andorra","country":"Andorra","count":2},{"_id":"Albania","country":"Albania","count":13}],
    //transactionStatsWeekly: [{"meta":[{"totalDeposits":1000100,"totalWithdrawal":0,"totalNet":1000100}],"data":[{"_id":"2021-06-14","day":14,"dayOfWeek":2,"dayOfYear":165,"monthOfYear":6,"year":2021,"deposit":100,"withdraw":0,"net":100},{"_id":"2021-06-16","day":16,"dayOfWeek":4,"dayOfYear":167,"monthOfYear":6,"year":2021,"deposit":1000000,"withdraw":0,"net":1000000},{"_id":"2021-06-16","day":18,"dayOfWeek":4,"dayOfYear":167,"monthOfYear":6,"year":2021,"deposit":100000,"withdraw":0,"net":100000}]}],
  //  transactionStatsMonthly: [{"meta":[{"totalDeposits":1131045269,"totalWithdrawal":1,"totalNet":1131045268}],"data":[{"_id":"2021-05-21","day":21,"dayOfWeek":6,"dayOfYear":141,"monthOfYear":5,"year":2021,"deposit":20000,"withdraw":0,"net":20000},{"_id":"2021-06-01","day":1,"dayOfWeek":3,"dayOfYear":152,"monthOfYear":6,"year":2021,"deposit":1130001000,"withdraw":0,"net":1130001000},{"_id":"2021-06-02","day":2,"dayOfWeek":4,"dayOfYear":153,"monthOfYear":6,"year":2021,"deposit":10000,"withdraw":0,"net":10000},{"_id":"2021-06-14","day":14,"dayOfWeek":2,"dayOfYear":165,"monthOfYear":6,"year":2021,"deposit":100,"withdraw":0,"net":100},{"_id":"2021-06-16","day":16,"dayOfWeek":4,"dayOfYear":167,"monthOfYear":6,"year":2021,"deposit":1000000,"withdraw":0,"net":1000000}]}]
     monthlySalesTarget: [{"_id":"602255a092a1d2285b9d65c8","userId":{"isActive":true,"_id":"5f95657504947a01cc19e9c0","firstName":"Gopakumar","lastName":"Gopalan","email":"gopakumar@exiniti.com"},"target":0,"moneyin":75000,"moneyout":null,"totalAccount":82,"totalDeposit":138695.5,"totalWithdrawal":6472,"createdAt":"2021-02-09T09:28:00.629Z"},{"_id":"604dbdf9412b3f0a14200f1a","userId":{"isActive":true,"_id":"603d0727d19a3e30688c228b","firstName":"Muhammed","lastName":"Aadil","email":"o@exiniti.com"},"target":444,"moneyin":4444,"totalAccount":0,"totalDeposit":0,"totalWithdrawal":0,"createdAt":"2021-03-14T07:40:41.489Z"},{"_id":"604883491f55830cec482858","userId":{"isActive":true,"_id":"600e98bf09cf35620a69c79e","firstName":"Abdul Rehman","lastName":"Siddiqui","email":"a@exiniti.com"},"target":333,"moneyin":333,"totalAccount":0,"totalDeposit":0,"totalWithdrawal":0,"createdAt":"2021-03-10T08:28:57.288Z"},{"_id":"602255c492a1d2285b9d65c9","userId":{"isActive":true,"_id":"5ff5a5a12ac413479c3d350d","firstName":"John","lastName":"Smith","email":"jsmith@exiniti.com"},"target":22,"moneyin":222,"moneyout":null,"totalAccount":0,"totalDeposit":0,"totalWithdrawal":0,"createdAt":"2021-02-09T09:28:36.961Z"}],
  
     transactionStatsWeekly: [{"meta":[{"totalDeposits":382000,"totalWithdrawal":173000,"totalNet":500000}],"data":[{"_id":"2021-06-10","day":14,"dayOfWeek":1,"dayOfYear":165,"monthOfYear":6,"year":2021,"deposit":80000,"withdraw":20000,"net":60000},{"_id":"2021-06-10","day":14,"dayOfWeek":4,"dayOfYear":165,"monthOfYear":6,"year":2021,"deposit":80000,"withdraw":20000,"net":60000},{"_id":"2021-06-10","day":14,"dayOfWeek":5,"dayOfYear":165,"monthOfYear":6,"year":2021,"withdraw":120000,"deposit":140000,"net":20000},{"_id":"2021-06-10","day":14,"dayOfWeek":6,"dayOfYear":165,"monthOfYear":6,"year":2021,"deposit":12000,"withdraw":3000,"net":106000},{"_id":"2021-06-10","day":14,"dayOfWeek":7,"dayOfYear":165,"monthOfYear":6,"year":2021,"deposit":150000,"withdraw":30000,"net":120000}]}],
    //transactionStatsMonthly: [{"meta":[{"totalDeposits":1131045269,"totalWithdrawal":1,"totalNet":1131045268}],"data":[{"_id":"2021-06-01","day":1,"dayOfWeek":4,"dayOfYear":140,"monthOfYear":6,"year":2021,"deposit":10300010,"withdraw":1365980,"net":8934030},{"_id":"2021-06-08","day":3,"dayOfWeek":4,"dayOfYear":155,"monthOfYear":6,"year":2021,"deposit":8500000,"withdraw":5510000,"net":2990000},{"_id":"2021-06-15","day":3,"dayOfWeek":4,"dayOfYear":160,"monthOfYear":6,"year":2021,"deposit":10325000,"withdraw":2053659,"net":8271341},{"_id":"2021-06-18","day":16,"dayOfWeek":4,"dayOfYear":155,"monthOfYear":6,"year":2021,"deposit":10000000,"withdraw":8254852,"net":1745148}]}],
    transactionStatsYearly: [{"meta":[{"totalDeposits":50469317060.37,"totalWithdrawal":517938.05,"totalNet":50468799122.32}],"data":[{"_id":"2020-06","day":7,"dayOfWeek":2,"dayOfYear":342,"monthOfYear":6,"year":2020,"deposit":11050000,"withdraw":5841151,"net":5208849},{"_id":"2020-08","day":7,"dayOfWeek":2,"dayOfYear":342,"monthOfYear":8,"year":2020,"deposit":11050000000,"withdraw":5800410151,"net":5249589849},{"_id":"2020-10","day":7,"dayOfWeek":2,"dayOfYear":342,"monthOfYear":10,"year":2020,"deposit":9548469525,"withdraw":1548469525,"net":8000000000},{"_id":"2020-12","day":7,"dayOfWeek":2,"dayOfYear":342,"monthOfYear":12,"year":2020,"deposit":12548469525,"withdraw":9854751258,"net":2693718267},{"_id":"2021-01","day":3,"dayOfWeek":1,"dayOfYear":3,"monthOfYear":1,"year":2021,"deposit":20000000000,"withdraw":10256487458,"net":9743512542},{"_id":"2021-02","day":1,"dayOfWeek":2,"dayOfYear":32,"monthOfYear":2,"year":2021,"deposit":50369054671.5,"withdraw":506228.05,"net":50368548443.45},{"_id":"2020-10","day":1,"dayOfWeek":2,"dayOfYear":32,"monthOfYear":2,"year":2021,"deposit":5036905467,"withdraw":50622800,"net":4986282667},{"_id":"2021-03","day":2,"dayOfWeek":3,"dayOfYear":61,"monthOfYear":3,"year":2021,"deposit":15050000000,"withdraw":11050000000,"net":4000000000},{"_id":"2021-04","day":5,"dayOfWeek":2,"dayOfYear":95,"monthOfYear":4,"year":2021,"deposit":1001219834,"withdraw":51231564,"net":949988270},{"_id":"2021-05","day":3,"dayOfWeek":2,"dayOfYear":123,"monthOfYear":5,"year":2021,"deposit":5555500000,"withdraw":785845960,"net":4769654040},{"_id":"2021-06","day":7,"dayOfWeek":2,"dayOfYear":158,"monthOfYear":6,"year":2021,"deposit":4615000,"withdraw":1000500,"net":3614500}]}],
   
    transactionStatsMonthly: [{"meta":[{"totalDeposits":49310,"totalWithdrawal":15,"totalNet":49295}],"data":[{"_id":"2021-05-23","day":23,"dayOfWeek":1,"dayOfYear":143,"monthOfYear":5,"year":2021,"deposit":10000,"withdraw":8000,"net":2000},{"_id":"2021-05-27","day":27,"dayOfWeek":5,"dayOfYear":147,"monthOfYear":5,"year":2021,"deposit":18000,"withdraw":13000,"net":5000},{"_id":"2021-05-30","day":30,"dayOfWeek":1,"dayOfYear":150,"monthOfYear":5,"year":2021,"deposit":10000,"withdraw":7000,"net":3000},{"_id":"2021-06-07","day":7,"dayOfWeek":2,"dayOfYear":158,"monthOfYear":6,"year":2021,"deposit":30000,"withdraw":10000,"net":20000},{"_id":"2021-06-08","day":8,"dayOfWeek":3,"dayOfYear":159,"monthOfYear":6,"year":2021,"deposit":20000,"withdraw":0,"net":20000},{"_id":"2021-06-10","day":10,"dayOfWeek":5,"dayOfYear":161,"monthOfYear":6,"year":2021,"deposit":5150,"withdraw":15,"net":5135},{"_id":"2021-06-13","day":13,"dayOfWeek":1,"dayOfYear":164,"monthOfYear":6,"year":2021,"deposit":1000,"withdraw":0,"net":1000}]}],
    
    ibStatsWeekly: [{"meta":[{"totalIb":4}],"data":[{"date":"2021-06-15","day":15,"dayOfWeek":3,"dayOfYear":166,"monthOfYear":6,"year":2021,"count":330},{"date":"2021-06-16","day":16,"dayOfWeek":4,"dayOfYear":167,"monthOfYear":6,"year":2021,"count":1},{"date":"2021-06-17","day":17,"dayOfWeek":5,"dayOfYear":168,"monthOfYear":6,"year":2021,"count":2}]}],
    rebCommStatsWeekly: [{"meta":[{"totalIb":162}],"data":[{"date":"2021-06","day":3,"dayOfWeek":3,"dayOfYear":158,"monthOfYear":6,"year":2021,"rebate":8000,"commission":4858}, {"date":"2021-06","day":4,"dayOfWeek":4,"dayOfYear":158,"monthOfYear":6,"year":2021,"rebate":2000,"commission":5000}, {"date":"2021-06","day":5,"dayOfWeek":5,"dayOfYear":158,"monthOfYear":6,"year":2021,"rebate":800,"commission":500}, {"date":"2021-06","day":6,"dayOfWeek":6,"dayOfYear":158,"monthOfYear":6,"year":2021,"rebate":1500,"commission":1800}, {"date":"2021-06","day":1,"dayOfWeek":1,"dayOfYear":158,"monthOfYear":6,"year":2021,"rebate":5000,"commission":3569}]}],
    
    ibStatsMonthly: [{"meta":[{"totalIb":26}],"data":[{"date":"2021-06-07","day":7,"dayOfWeek":2,"dayOfYear":158,"monthOfYear":6,"year":2021,"count":65},{"date":"2021-06-08","day":8,"dayOfWeek":3,"dayOfYear":159,"monthOfYear":6,"year":2021,"count":88},{"date":"2021-06-10","day":10,"dayOfWeek":5,"dayOfYear":161,"monthOfYear":6,"year":2021,"count":90},{"date":"2021-06-13","day":13,"dayOfWeek":1,"dayOfYear":164,"monthOfYear":6,"year":2021,"count":40},{"date":"2021-06-14","day":14,"dayOfWeek":2,"dayOfYear":165,"monthOfYear":6,"year":2021,"count":70},{"date":"2021-06-15","day":15,"dayOfWeek":3,"dayOfYear":166,"monthOfYear":6,"year":2021,"count":30},{"date":"2021-06-16","day":16,"dayOfWeek":4,"dayOfYear":167,"monthOfYear":6,"year":2021,"count":25},{"date":"2021-06-17","day":17,"dayOfWeek":5,"dayOfYear":168,"monthOfYear":6,"year":2021,"count":55}, {"date":"2021-06-17","day":17,"dayOfWeek":5,"dayOfYear":168,"monthOfYear":6,"year":2021,"count":66}]}],
    rebCommStatsMonthly: [{"meta":[{"_id":null,"count":3,"totalRebate":803.3688089723407,"totalCommission":505.4}],"data":[{"date":"2021-06-07","day":7,"dayOfWeek":2,"dayOfYear":158,"monthOfYear":6,"year":2021,"rebate":10000,"commission":6000},{"date":"2021-06-08","day":8,"dayOfWeek":3,"dayOfYear":159,"monthOfYear":6,"year":2021,"rebate":1100,"commission":1550},{"date":"2021-06-10","day":10,"dayOfWeek":5,"dayOfYear":161,"monthOfYear":6,"year":2021,"rebate":2000,"commission":1485}, {"date":"2021-06-12","day":12,"dayOfWeek":5,"dayOfYear":161,"monthOfYear":6,"year":2021,"rebate":6598,"commission":3526}, {"date":"2021-05-28","day":28,"dayOfWeek":5,"dayOfYear":161,"monthOfYear":5,"year":2021,"rebate":4000,"commission":2958}, {"date":"2021-06-14","day":14,"dayOfWeek":5,"dayOfYear":161,"monthOfYear":6,"year":2021,"rebate":4500,"commission":3100},  {"date":"2021-05-30","day":30,"dayOfWeek":5,"dayOfYear":161,"monthOfYear":5,"year":2021,"rebate":5500,"commission":3800}, {"date":"2021-06-03","day":3,"dayOfWeek":5,"dayOfYear":161,"monthOfYear":6,"year":2021,"rebate":7500,"commission":6897}, {"date":"2021-06-04","day":3,"dayOfWeek":5,"dayOfYear":161,"monthOfYear":6,"year":2021,"rebate":6500,"commission":4502}]}],
   
    ibStatsYearly: [{"meta":[{"totalIb":162}],"data":[{"date":"2021-08","day":24,"dayOfWeek":1,"dayOfYear":24,"monthOfYear":8,"year":2021,"count":200},{"date":"2021-01","day":24,"dayOfWeek":1,"dayOfYear":24,"monthOfYear":1,"year":2021,"count":17},{"date":"2021-02","day":3,"dayOfWeek":4,"dayOfYear":34,"monthOfYear":2,"year":2021,"count":95},{"date":"2021-03","day":14,"dayOfWeek":1,"dayOfYear":73,"monthOfYear":3,"year":2021,"count":46},{"date":"2021-04","day":15,"dayOfWeek":5,"dayOfYear":105,"monthOfYear":4,"year":2021,"count":8},{"date":"2021-06","day":7,"dayOfWeek":2,"dayOfYear":158,"monthOfYear":6,"year":2021,"count":26}]}],
    rebCommStatsYearly: [{"meta":[{"_id":null,"count":1,"totalRebate":10803.3688089723408,"totalCommission":1000}],"data":[{"date":"2021-09","day":7,"dayOfWeek":2,"dayOfYear":158,"monthOfYear":9,"year":2021,"rebate":14000,"commission":12000},{"date":"2021-07","day":7,"dayOfWeek":2,"dayOfYear":158,"monthOfYear":7,"year":2021,"rebate":12000,"commission":9000},{"date":"2021-08","day":7,"dayOfWeek":2,"dayOfYear":158,"monthOfYear":8,"year":2021,"rebate":8000,"commission":4858},{"date":"2021-06","day":7,"dayOfWeek":2,"dayOfYear":158,"monthOfYear":6,"year":2021,"rebate":8000,"commission":4858}, {"date":"2021-01","day":7,"dayOfWeek":2,"dayOfYear":158,"monthOfYear":1,"year":2021,"rebate":15000,"commission":8000}, {"date":"2021-03","day":7,"dayOfWeek":2,"dayOfYear":158,"monthOfYear":3,"year":2021,"rebate":12000,"commission":9222}, {"date":"2020-11","day":7,"dayOfWeek":2,"dayOfYear":158,"monthOfYear":11,"year":2020,"rebate":7000,"commission":5065}, {"date":"2021-02","day":7,"dayOfWeek":2,"dayOfYear":158,"monthOfYear":2,"year":2021,"rebate":0,"commission":2000}]}],
    
   
}

export default demoJSON;