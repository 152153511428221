/**
 * Reduer for user
 */

import {
  FETCH_CLIENT_DETAIL,
  UPDATE_CLIENT_PARTNERSHIP,
  GET_CLIENT_DETAIL,
  FETCH_CLIENT_TOTAL_ACCOUNTS,
  UPDATE_CLIENT_DETAIL,
  FETCH_CLIENT_EMPLOYMENT_DETAIL,
  FETCH_CLIENT_DIRECTORS_DETAIL,
  FETCH_CLIENT_SHAREHOLDERS_DETAIL,
  FETCH_CLIENT_FINANCIAL_INFORMATION,
  FETCH_CLIENT_DECLARATIONS,
  FETCH_IB_QUESTIONAIRE,
  RESET_CLIENT_PASSWORD,
  FETCH_CLIENT_STATUS,
  FETCH_KYC_STATUS,
  UPDATE_CLIENT_STATUS,
  FETCH_CALL_STATUS,
  UPDATE_CALL_STATUS,
  LINK_SUB_IB,
  UNLINK_SUB_IB,
  CLIENT_REQUEST_IB_APPOVED_STATUS,
  CLIENT_OTHER_REQUEST_IB_APPOVED_STATUS,
  RESET_PAGE_CLIENT_PROFILE,
  SET_EXTRA_DETAILS
} from '../actions/actionTypes';

const initialState = {
  client: {
    firstName: '',
    lastName: '',
    email: '',
    createdAt: '',
    currency: ''
  },
  clientDetailLoadingSuccess: false,
  clientDetailLoading: false,
  clientDetailLoadingErrorMessage: '',
  clientDetailLoadingError: null,

  clientTotalAccounts: 0,
  clientTotalAccountsLoading: false,

  updateProfileLoading: false,
  updateProfileResult: {},
  updateProfileSuccess: false,
  updateProfileError: '',
  ibQuestionaire: {},

  kyc: {},
  profileKyc: {},

  isActive: true,
  hasUpdate: false,
  actionLoading: false,
  requestIbApprovedStatus: false,
  requestOtherIbApprovedStatus: false,
  extraDetails:[]
};

const updateStatus = (state, data) => {
  const newState = {
    actionLoading: data.actionLoading
  };
  if (data.client) {
    newState.client = {
      ...state.client,
      callStatus: data.client.callStatus
    };
  }
  return newState;
};

const updateClientDetail = (state, data) => {
  const newState = {
    ...data
  };
  if(data.client) {
    newState.client = {
      ...state.client,
      ...data.updateProfileResult
    };
  }
  return newState;
}

const clientDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENT_DETAIL:
      return { ...state };
    case UPDATE_CLIENT_DETAIL:
      return { ...state, ...updateClientDetail(state, action.data) };
    case FETCH_CLIENT_EMPLOYMENT_DETAIL:
      return { ...state, ...action.data };
    case FETCH_CLIENT_DIRECTORS_DETAIL:
      return { ...state, ...action.data };
    case FETCH_CLIENT_SHAREHOLDERS_DETAIL:
      return { ...state, ...action.data };
    case FETCH_CLIENT_FINANCIAL_INFORMATION:
      return { ...state, ...action.data };
    case FETCH_CLIENT_DECLARATIONS:
      return { ...state, ...action.data };
    case FETCH_IB_QUESTIONAIRE:
      return { ...state, ...action.data };
    case FETCH_CLIENT_DETAIL:
      return { ...state, ...action.data };
    case UPDATE_CLIENT_PARTNERSHIP:
      return { ...state, client: {
        ...state.client,
        partnership: action.data,
      } };
    case FETCH_CLIENT_TOTAL_ACCOUNTS:
      return { ...state, ...action.data };
    case FETCH_KYC_STATUS:
      return { ...state, ...action.data };
    case LINK_SUB_IB:
      return { ...state, ...action.data };
    case UNLINK_SUB_IB:
      return { ...state, ...action.data };
    case FETCH_CALL_STATUS:
      return {
        ...state,
        client: {
          ...state.client,
          callStatus: action.data.callStatus
        },
        actionLoading: false
      };
    case RESET_CLIENT_PASSWORD:
    case FETCH_CLIENT_STATUS:
    case UPDATE_CLIENT_STATUS:
    case UPDATE_CALL_STATUS:
      return {
        ...state,
        ...updateStatus(state, action.data)
      };
    case CLIENT_REQUEST_IB_APPOVED_STATUS:
      return {
        ...state,
        requestIbApprovedStatus: action.data
      };
    case CLIENT_OTHER_REQUEST_IB_APPOVED_STATUS:
      return {
        ...state,
        requestOtherIbApprovedStatus: action.data
      }
    case RESET_PAGE_CLIENT_PROFILE:
      return {
        ...initialState
      };
      case SET_EXTRA_DETAILS:
        return {
          ...state,
          extraDetails: action.payload
        };
    default:
      return state;
  }
};

export default clientDetailReducer;
