/**
 * Reduer for user
 */

import { USER_LOGIN_SUCCESS, GET_ALL_CALENDAR } from '../actions/actionTypes';

const initialState = {
  isLoggedIn: false,
  email: '',
  phone: '',
  name: '',
  token: '',
  isLoading: false,
  reminders: []
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      return action.currentUser;
    case GET_ALL_CALENDAR:
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

export default AuthReducer;
